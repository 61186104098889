import { useCallback, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { ReactComponent as SwapIcon } from "../../assets/icons/swapIcon.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as BondIcon } from "../../assets/icons/bond.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as OlympusIcon } from "../../assets/icons/olympus-nav-header.svg";
import { ReactComponent as PoolTogetherIcon } from "../../assets/icons/33-together.svg";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo-footer.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { Trans } from "@lingui/macro";
import { trim, shorten } from "../../helpers";
// import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import useBonds from "../../hooks/Bonds";
import { Paper, Link, Box, Typography, SvgIcon } from "@mui/material";
import { Skeleton } from "@mui/material";
import "./sidebar.scss";
import { useIsPath, useMobile } from "../../hooks";
import { useChainId } from "wagmi";
import { addresses, TOKEN_DECIMALS } from "src/constants";
import Social from "../Footer/Social";
import { useDispatch } from "react-redux";
import { info } from "src/slices/MessagesSlice";

function NavContent({ mobileOpen, handleDrawerToggle }) {
  const { isSmallScreen } = useMobile();
  const [isActive] = useState();
  const dispatch = useDispatch();

  // const { chainID } = useWeb3Context();
  const chainID = useChainId();
  const { bonds } = useBonds(chainID);
  const isHome = useIsPath("/home");
  const isDashboard = useIsPath("/dashboard");
  const isInvite = useIsPath("/invite");
  const isBond = useIsPath("/bonds");
  const isStake = useIsPath("/stake");
  const isContribution = useIsPath("/contribution");
  const isCommunity = useIsPath("/community");

  let history = useHistory();
  const goPancakeSwap = () => {
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
    //     addresses[chainID].USDT_ADDRESS
    //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    history.push("/swap");
  };

  const goV1Version = () => {
    window.open(``, "_blank");
  };

  const handleComing = () => {
    dispatch(info("Coming soon"));
  };

  return (
    <Paper
      sx={{
        height: "auto",
        width: "345px",
        borderRadius: "4px",
        border: "1px solid #2D2D2D",
        background: "#202020",
        boxShadow:
          "0px 49px 14px 0px rgba(0, 0, 0, 0.01), 0px 31px 13px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.30), 0px 8px 8px 0px rgba(0, 0, 0, 0.51), 0px 2px 4px 0px rgba(0, 0, 0, 0.59)",
      }}
    >
      <Box
        sx={{
          width: "345px",
          minHeight: "300px",
          p: "20px",
          // background: "skyblue",
        }}
        display={"flex"}
        // alignItems={"center"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          sx={{
            width: "100%",
            pb: "24px",
          }}
        >
          <Box>
            <LogoIcon
              style={{
                width: "157px",
                height: "34px",
              }}
            />
          </Box>
          <Box onClick={handleDrawerToggle}>
            <CloseIcon />
          </Box>
        </Box>
        <Box sx={{ width: "100%", mb: "40px" }}>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
          >
            <Link
              component={NavLink}
              to="/home"
              className={`button-dapp-menu ${isHome ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Home</Trans>
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
            display="flex"
            alignItems={"center"}
          >
            <Link
              // component={NavLink}
              // to="/dashboard"
              component={"div"}
              onClick={handleComing}
              className={`button-dapp-menu ${isDashboard ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Dashboard</Trans>
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
            display="flex"
            alignItems={"center"}
          >
            <Link
              // component={NavLink} to="/stake"
              component={"div"}
              onClick={handleComing}
              className={`button-dapp-menu ${isStake ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Stake</Trans>
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
            display="flex"
            alignItems={"center"}
          >
            <Link
              component={"div"}
              onClick={handleComing}
              //  component={NavLink} to="/bond"
              className={`button-dapp-menu ${isBond ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Bond</Trans>
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
            display="flex"
            alignItems={"center"}
          >
            <Link
              component={"div"}
              // component={NavLink}
              // to="/contribution"
              onClick={handleComing}
              className={`button-dapp-menu ${isContribution ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Contribution</Trans>
              </Typography>
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              py: "13px",
              borderBottom: "1px solid #2d2d2d",
            }}
          >
            <Link
              component={NavLink}
              to="/invite"
              className={`button-dapp-menu ${isInvite ? "active" : ""}`}
            >
              <Typography align="left">
                <Trans>Invite</Trans>
              </Typography>
            </Link>
          </Box>
        </Box>
        {/* socials */}
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Social />
        </Box>
      </Box>
    </Paper>
  );
}

export default NavContent;
