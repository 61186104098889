import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import ierc20AbiJson from "../abi/IERC20.json";
import sOHMv2Json from "../abi/sOhmv2.json";
import OlympusStakingABIJson from "../abi/OlympusStakingv2.json";

import { getTokenDecimals, setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";

const ierc20Abi = ierc20AbiJson.abi;
const sOHMv2 = sOHMv2Json.abi;
const OlympusStakingABI = OlympusStakingABIJson.abi;

interface IUserBalances {
  balances: {
    ohm: string;
    sOHM: string;
    fsohm: string;
    wsohm: string;
    wsohmAsSohm: string;
    pool: string;
    // busd: string;
  };
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    try {
      const ohmContract = new ethers.Contract(
        addresses[networkID].OHM_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const ohmBalance = await ohmContract.balanceOf(address);

      // const thaExchangeContract = new ethers.Contract(
      //   addresses[networkID].THA_EXCHANGE_ADDRESS as string,
      //   THAExchangeAbi,
      //   provider,
      // );
      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      // const thaContract = new ethers.Contract(addresses[networkID].THA_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      const USDTContract = new ethers.Contract(
        addresses[networkID].USDT_REAL_ADDRESS as string,
        sOHMv2,
        provider,
      ) as SOhmv2;
      const sohmBalance = await sohmContract.balanceOf(address);
      const daiContract = new ethers.Contract(addresses[networkID].DAI_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      const daiBalance = await daiContract.balanceOf(address);
      const usdtBalance = await USDTContract.balanceOf(address);
      // const thaBalance = await thaContract.balanceOf(address);

      // const usdtAllowanceInTHAExchange = await USDTContract.allowance(
      //   address,
      //   addresses[networkID].THA_EXCHANGE_ADDRESS,
      // );
      // console.log("get balance 2 usdtAllowanceInTHAExchange", usdtAllowanceInTHAExchange);


      let gonsBal, isLocked;
      // if (networkID == 56) {
      const stakingContract = new ethers.Contract(
        addresses[networkID].STAKING_ADDRESS as string,
        OlympusStakingABI,
        provider,
      ) as OlympusStakingv2;
      // console.log("BNnb first", ohmBalance, sohmBalance, thaBalance);
      const info = await stakingContract.warmupInfo(address);
      // console.log("info", info.gons);
      gonsBal = await sohmContract.balanceForGons(info.gons);
      console.log("gonsBal", gonsBal);
      const epoch = await stakingContract.epoch();

      console.log("epoch", epoch);
      isLocked = Number(epoch.number) < Number(info.expiry);

      const [daiDecimals, usdtDecimals] = await Promise.all([
        getTokenDecimals(addresses[networkID].DAI_ADDRESS as string, networkID),
        getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
      ]);
      return {
        balances: {
          ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
          sOHM: ethers.utils.formatUnits(sohmBalance, "gwei"),
          gonsBal: gonsBal && ethers.utils.formatUnits(gonsBal, "gwei"),
          dai: ethers.utils.formatUnits(daiBalance, daiDecimals),
          usdt: ethers.utils.formatUnits(usdtBalance, usdtDecimals),
          // bnb: ethers.utils.formatUnits(bnbBalance, "18"),
          // tha: ethers.utils.formatUnits(thaBalance, thaDecimals),
        },
        isLocked,
        // usdtAllowanceInTHAExchange: ethers.utils.formatUnits(usdtAllowanceInTHAExchange, usdtDecimals),
        // thaExchangeRate,
      };
    } catch (error) {
      console.log("erro", error);
    }
  },
);

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    try {
      const ohmContract = new ethers.Contract(
        addresses[networkID].OHM_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);

      const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;
      const usdtContract = new ethers.Contract(
        addresses[networkID].USDT_REAL_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      const daiContract = new ethers.Contract(
        addresses[networkID].DAI_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

      

    


      const ohmAllowanceInRouter = await ohmContract.allowance(address, addresses[networkID].pancakeRouter);
      const usdtAllowanceInRouter = await usdtContract.allowance(address, addresses[networkID].pancakeRouter);
      const daiAllowanceInRouter = await daiContract.allowance(address, addresses[networkID].pancakeRouter);
      // console.log("loading 1 getBalances", ohmAllowanceInRouter, usdtAllowanceInRouter);
      await dispatch(getBalances({ address, networkID, provider }));
      // console.log("loading 2 getBalances", rewardList);
      const [usdtDecimals] = await Promise.all([
        getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
      ]);
      return {
        staking: {
          ohmStake: +stakeAllowance,
          ohmUnstake: +unstakeAllowance,
        },
        ohmAllowanceInRouter: ethers.utils.formatUnits(ohmAllowanceInRouter, "9"),
        usdtAllowanceInRouter: ethers.utils.formatUnits(usdtAllowanceInRouter, usdtDecimals),
        daiAllowanceInRouter: ethers.utils.formatUnits(daiAllowanceInRouter, "18"),
      };
    } catch (error) {
      console.log("loading error", error);
    }
  },
);

export interface IUserBondDetails {
  allowance: number[];
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}

export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }

    try {
      const bondContract = bond.getContractForBond(networkID, provider);
      const reserveContract = bond.getContractForReserve(networkID, provider);

      const daiContract = new ethers.Contract(
        addresses[networkID].USDT_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      const usdtContract = new ethers.Contract(
        addresses[networkID].USDT_REAL_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;
      // const usdcContract = new ethers.Contract(
      //   addresses[networkID].USDC_ADDRESS as string,
      //   ierc20Abi,
      //   provider,
      // ) as IERC20;
      // let bondDetails, interestDue;
      // try {
      let bondDetails = await bondContract.getBondInfoData(address);
      // reserveContract.address === "0xEa77804D131c0ad997333a5c795aFA54f2968F22" &&
      console.log("bondasd bondDetails", bondDetails, bond.name);
      if (bondDetails.length > 0) {
        if (bond.name === "ohm_dai_lp") {
          bondDetails = await Promise.all(
            bondDetails.map(async (bondItem: any) => {
              let pendingPayout, bondMaturationBlock;

              let interestDue: BigNumberish = Number(bondItem.payout.toString()) / Math.pow(10, 9);
              // // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
              bondMaturationBlock = +bondItem.vesting + +bondItem.lastBlock;
              pendingPayout = await bondContract.pendingPayoutFor(address, bondItem.id, false);

              let allowance,
                balance = BigNumber.from(0);
              allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
              balance = await reserveContract.balanceOf(address);

              const daiBalance = await daiContract.balanceOf(address);
              // console.log("bondhelper", bond.getAddressForBondHelper(networkID));
              const daiAllowance = await daiContract.allowance(
                address,
                bond.getAddressForBondHelper(networkID) as string,
              );
              // formatEthers takes BigNumber => String
              const balanceVal = ethers.utils.formatEther(balance);
              // balanceVal should NOT be converted to a number. it loses decimal precision

              // const usdcBalance = await usdcContract.balanceOf(address);
              // const usdcAllowance = await usdcContract.allowance(
              //   address,
              //   bond.getAddressForBondHelper(networkID) as string,
              // );

              const usdtBalance = await usdtContract.balanceOf(address);
              const usdtAllowance = await usdtContract.allowance(
                address,
                bond.getAddressForBondHelper(networkID) as string,
              );
              const [usdtDecimals] = await Promise.all([
                getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
              ]);
              return {
                bond: bond.name,
                displayName: bond.displayName,
                bondIconSvg: bond.bondIconSvg,
                isLP: bond.isLP,
                allowance: [Number(daiAllowance.toString()), Number(usdtAllowance.toString())],
                balance: [ethers.utils.formatEther(daiBalance), ethers.utils.formatUnits(usdtBalance, usdtDecimals)],
                interestDue,
                bondMaturationBlock,
                pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                id: bondItem.id,
              };
            }),
          );
        } else {
          bondDetails = await Promise.all(
            bondDetails.map(async (bondItem: any) => {
              let pendingPayout, bondMaturationBlock;

              let interestDue: BigNumberish = Number(bondItem.payout.toString()) / Math.pow(10, 9);
              bondMaturationBlock = +bondItem.vesting + +bondItem.lastBlock;
              pendingPayout = await bondContract.pendingPayoutFor(address, bondItem.id, false);

              let allowance,
                balance = BigNumber.from(0);
              allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
              balance = await reserveContract.balanceOf(address);
              const decimals = await getTokenDecimals(reserveContract.address, networkID);
              // formatEthers takes BigNumber => String
              const balanceVal = ethers.utils.formatUnits(balance, decimals);
              // balanceVal should NOT be converted to a number. it loses decimal precision
              return {
                bond: bond.name,
                displayName: bond.displayName,
                bondIconSvg: bond.bondIconSvg,
                isLP: bond.isLP,
                allowance: [Number(allowance.toString()), Number(allowance.toString())],
                balance: [balanceVal, balanceVal],
                interestDue,
                bondMaturationBlock,
                pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
                id: bondItem.id,
              };
            }),
          );
        }
        console.log(" bondasd bondDetails", bondDetails);
        return bondDetails;
      } else {
        if (bondDetails.length == 0) {
          if (bond.name === "ohm_dai_lp") {
            // if (bond.name === "usdt" || bond.name === "ohm_dai_lp" || bond.name === "usdc" || bond.name === "dai") {
            let allowance,
              balance = BigNumber.from(0);
            allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
            balance = await reserveContract.balanceOf(address);
            const daiBalance = await daiContract.balanceOf(address);
            console.log("bondasd bondDetails daiBalance", daiBalance, bond.getAddressForBondHelper(networkID));
            const daiAllowance = await daiContract.allowance(
              address,
              bond.getAddressForBondHelper(networkID) as string,
            );
            const usdtBalance = await usdtContract.balanceOf(address);
            // // // console.log("bondhelper", bond.getAddressForBondHelper(networkID));
            const usdtAllowance = await usdtContract.allowance(
              address,
              bond.getAddressForBondHelper(networkID) as string,
            );

            const balanceVal = ethers.utils.formatEther(balance);

            // formatEthers takes BigNumber => String

            // balanceVal should NOT be converted to a number. it loses decimal precision

            // const usdcBalance = await usdcContract.balanceOf(address);
            // const usdcAllowance = await usdcContract.allowance(
            //   address,
            //   bond.getAddressForBondHelper(networkID) as string,
            // );
            const [usdtDecimals] = await Promise.all([
              getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS as string, networkID),
            ]);
            return [
              {
                bond: bond.name,
                displayName: bond.displayName,
                bondIconSvg: bond.bondIconSvg,
                isLP: bond.isLP,
                allowance: [
                  Number(daiAllowance.toString()),
                  // Number(allowance.toString()),
                  Number(usdtAllowance.toString()),
                  // Number(usdcAllowance.toString()),
                ],
                balance: [
                  ethers.utils.formatEther(daiBalance),
                  // balanceVal,
                  ethers.utils.formatUnits(usdtBalance, usdtDecimals),
                  // ethers.utils.formatUnits(usdcBalance, usdcDecimals),
                ],
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
              },
            ];
          } else {
            let allowance,
              balance = BigNumber.from(0);
            allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
            balance = await reserveContract.balanceOf(address);
            const decimals = await getTokenDecimals(reserveContract.address, networkID);
            console.log("bondasd balance", bond.name, balance, "decimals", decimals);
            // formatEthers takes BigNumber => String
            const balanceVal = ethers.utils.formatUnits(balance, decimals);
            allowance = ethers.utils.formatUnits(allowance, decimals);
            return [
              {
                bond: bond.name,
                displayName: bond.displayName,
                bondIconSvg: bond.bondIconSvg,
                isLP: bond.isLP,
                allowance: [Number(allowance.toString()), Number(allowance.toString())],
                balance: [balanceVal, balanceVal],
                interestDue: 0,
                bondMaturationBlock: 0,
                pendingPayout: "",
              },
            ];
          }
        } else {
          let pendingPayout, bondMaturationBlock;

          let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
          // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
          bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
          pendingPayout = await bondContract.pendingPayoutFor(address);
          // } catch (error) {
          //   console.log("err", error);
          // }
          // console.log("bondDetails", bondDetails);

          let allowance,
            balance = BigNumber.from(0);
          allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
          balance = await reserveContract.balanceOf(address);
          const usdtBalance = await usdtContract.balanceOf(address);
          const usdtAllowance = await usdtContract.allowance(
            address,
            bond.getAddressForBondHelper(networkID) as string,
          );
          // formatEthers takes BigNumber => String
          const balanceVal = ethers.utils.formatEther(balance);
          // balanceVal should NOT be converted to a number. it loses decimal precision

          return [
            {
              bond: bond.name,
              displayName: bond.displayName,
              bondIconSvg: bond.bondIconSvg,
              isLP: bond.isLP,
              allowance: [Number(usdtAllowance.toString()), Number(allowance.toString())],
              balance: [ethers.utils.formatEther(usdtBalance), balanceVal],
              interestDue,
              bondMaturationBlock,
              pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
            },
          ];
        }
      }
    } catch (error) {
      console.log(error, "bondContract error");
    }
  },
);

export const calculateUserDirectBondDetails = createAsyncThunk(
  "account/calculateUserDirectBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
      };
    }
    // dispatch(fetchBondInProgress());
    // const signer = provider.getSigner();
    // Calculate bond details.
    console.log("bond", bond);
    try {
      const bondContract = bond.getContractForBond(networkID, provider);
      const reserveContract = bond.getContractForReserve(networkID, provider);
      const busdContract = new ethers.Contract(
        addresses[networkID].USDT_ADDRESS as string,
        ierc20Abi,
        provider,
      ) as IERC20;

      // let bondDetails, interestDue;
      // try {
      let bondDetails = await bondContract.inviteBond(address);

      let pendingPayout, bondMaturationBlock;

      let interestDue: BigNumberish = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
      // interestDue = Number(bondDetails.payout.toString()) / Math.pow(10, 9);
      bondMaturationBlock = +bondDetails.vesting + +bondDetails.lastBlock;
      pendingPayout = await bondContract.pendingPayoutFor(address, "0", true);
      // } catch (error) {
      //   console.log("err", error);
      // }
      // console.log("bondDetails direct", bondDetails);
      let allowance,
        balance = BigNumber.from(0);
      allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
      balance = await reserveContract.balanceOf(address);
      const busdBalance = await busdContract.balanceOf(address);
      const busdAllowance = await busdContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
      // formatEthers takes BigNumber => String
      const balanceVal = ethers.utils.formatEther(balance);
      // balanceVal should NOT be converted to a number. it loses decimal precision
      return [
        {
          bond: bond.name,
          displayName: bond.displayName,
          bondIconSvg: bond.bondIconSvg,
          isLP: bond.isLP,
          allowance: [Number(busdAllowance.toString()), Number(allowance.toString())],
          balance: [ethers.utils.formatEther(busdBalance), balanceVal],
          interestDue,
          bondMaturationBlock,
          pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
        },
      ];
    } catch (error) {
      console.log(error, "calculateUserDirectBondDetails error");
    }
  },
);

interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  directBonds: { [key: string]: IUserBondDetails };
  balances: {
    ohm: string;
    sOHM: string;
    dai: string;
    oldsohm: string;
    fsohm: string;
    wsohm: string;
    wsohmAsSohm: string;
    pool: string;
    busd: string;
    gonsBal: string;
    usdt: string;
    usdc: string;
    wbnb: string;
    bnb: string;
    // xph: string;
    // sxph: string;
    xphV2: string;
  };
  loading: boolean;
  staking: {
    ohmStake: number | null;
    ohmUnstake: number | null;
  };
  reward: {
    community: number;
    staking: number;
    additionStaking: number;
    total: number;
    mint: number;
    fomoLottery: number;
    turboLottery: number;
  };
  pooling: {
    sohmPool: number;
  };
  isLocked: boolean;
  ohmAllowanceInRouter: string;
  busdAllowanceInRouter: string;
  usdtAllowanceInRouter: string;
  daiAllowanceInRouter: string;
  usdcAllowanceInRouter: string;
  wbnbAllowanceInRouter: string;
  ohmAllowanceInMigration: string;
  sohmAllowanceInMigration: string;
  usdtAllowanceInTHAExchange: string;
}

const initialState: IAccountSlice = {
  loading: false,
  bonds: {},
  directBonds: {},
  balances: {
    ohm: "",
    sOHM: "",
    dai: "",
    oldsohm: "",
    fsohm: "",
    wsohm: "",
    pool: "",
    wsohmAsSohm: "",
    busd: "",
    usdt: "",
    usdc: "",
    wbnb: "",
    bnb: "",
    gonsBal: "",
    // xph: "",
    // sxph: "",
    xphV2: "",
  },
  staking: { ohmStake: null, ohmUnstake: null },
  reward: { community: 0, staking: 0, additionStaking: 0, mint: 0, turboLottery: 0, fomoLottery: 0, total: 0 },
  // wrapping: { sohmWrap: 0, wsohmUnwrap: 0 },
  pooling: { sohmPool: 0 },
  isLocked: false,
  ohmAllowanceInRouter: "",
  busdAllowanceInRouter: "",
  usdtAllowanceInRouter: "",
  daiAllowanceInRouter: "",
  usdcAllowanceInRouter: "",
  wbnbAllowanceInRouter: "",
  ohmAllowanceInMigration: "",
  sohmAllowanceInMigration: "",
  usdtAllowanceInTHAExchange: "",
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        let bond;
        bond = action.payload[0] && action.payload[0].bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserDirectBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserDirectBondDetails.fulfilled, (state: any, action: any) => {
        if (!action.payload) return;
        let bond;
        bond = action.payload[0].bond;
        state.directBonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserDirectBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
