import React, { useState, ReactElement, useContext, useEffect, useMemo, useCallback } from "react";
import Web3Modal from "web3modal";
import { StaticJsonRpcProvider, JsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { IFrameEthereumProvider } from "@ledgerhq/iframe-provider";
import { EnvHelper } from "../helpers/Environment";
import { NodeHelper } from "src/helpers/NodeHelper";
import { useAccount, useChainId, useWalletClient, usePublicClient } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
// import { useDispatch } from "react-redux";
// import { error } from "../slices/MessagesSlice";
import { useEthersSigner } from "./wagmi";
export const useWeb3Context = () => {
  const chainID = useChainId();
  // const provider = usePublicClient();
  const { address, isConnected: connected, connector }: any = useAccount();
  // https://bsc-testnet.nodereal.io/v1/
  // https://data-seed-prebsc-2-s1.bnbchain.org:8545
  //
  const signer = useEthersSigner();
  // const { connect } = useConnect();
  const [provider, setProvider] = useState<any>(new StaticJsonRpcProvider(process.env.REACT_APP_RPC_URL));
  useEffect(() => {
    // if (connected) {
    const _provider = connected ? signer : new StaticJsonRpcProvider(process.env.REACT_APP_RPC_URL);
    setProvider(_provider);
  }, [connected, address]);
  const { openConnectModal: connect } = useConnectModal();
  return {
    address,
    chainID: connected ? chainID : Number(process.env.REACT_APP_CHAIN_ID),
    provider: signer || provider,
    connected,
    connect,
  };
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};
