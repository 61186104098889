import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  SvgIcon,
  Typography,
  Popper,
  Paper,
  Divider,
  Link,
  Slide,
  Drawer,
  Modal,
  Fade,
} from "@mui/material";
// import { useWeb3Context } from "src/hooks/web3Context";
import { t, Trans } from "@lingui/macro";
import { ConnectButton as RainbowConnectButton } from "@rainbow-me/rainbowkit";
import { useMobile } from "../../hooks";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as DexscreenerIcon } from "src/assets/icons/dexscreener.svg";
import { ReactComponent as PancakeSwapIcon } from "src/assets/icons/pancake_swap.svg";
import { ReactComponent as OhmIcon } from "src/assets/tokens/OHM.svg";
import { ReactComponent as SohmIcon } from "src/assets/tokens/sOHM.svg";
import { ReactComponent as UniswapIcon } from "src/assets/icons/uniswap.svg";
import TokenIcon from "src/assets/tokens/OHM.svg";
import STokenIcon from "src/assets/tokens/sOHM.svg";
import USDTIcon from "src/assets/tokens/USDT.svg";
import { useAppSelector } from "src/hooks";
import { shorten, trim } from "src/helpers";
import { addresses, TOKEN_DECIMALS } from "src/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Skeleton } from "@mui/material";
import styled from "@emotion/styled";
import { useChainId, useAccount, useDisconnect } from "wagmi";
import { useDispatch } from "react-redux";
import { success, error } from "src/slices/MessagesSlice";

function ConnectMenu({ showPopper }) {
  const { isSmallScreen } = useMobile();
  const chainID = useChainId();
  const dispatch = useDispatch();
  const [isWaringWrongNetwork, setIsWaringWrongNetwork] = useState(false);
  // console.log("chain", chainID);
  // const { connect, disconnect, connected, web3, chainID, address } = useWeb3Context();
  const networkID = chainID;
  const { isConnected: connected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const [anchorEl, setAnchorEl] = useState(null);

  const [isHovering, setIsHovering] = useState(false);
  const isAppLoading = useAppSelector((state) => state.app.loading);
  const marketPrice = useAppSelector((state) => {
    return state.app.marketPrice;
  });

  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;

  useEffect(() => {}, []);
  // const pendingTransactions = useSelector(state => {
  //   return state.pendingTransactions;
  // });
  const ohmBalance = useAppSelector((state) => {
    return state.account.balances.ohm;
  });

  const sohmBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.sOHM;
  });
  const usdtBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.usdt;
  });
  const thaBalance = useAppSelector((state) => {
    return state.account.balances && state.account.balances.tha;
  });
  // const thaExchangeRate = useAppSelector(state => {
  //   return state.account.thaExchangeRate;
  // });
  // console.log("thaExchangeRate", thaExchangeRate);
  // let buttonText = <Trans>Connect Wallet</Trans>;
  // let clickFunc = connect;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const addTokenToWallet = async (tokenSymbol, tokenAddress) => {
    // console.log("addTokenToWallet", tokenSymbol, tokenAddress);
    if (window.ethereum) {
      const host = window.location.origin;
      let tokenPath;
      let tokenDecimals = TOKEN_DECIMALS;
      switch (tokenSymbol) {
        case "OHM":
          tokenPath = TokenIcon;
          break;
        case "sOHM":
          tokenPath = STokenIcon;
          break;
        default:
          tokenPath = TokenIcon;
      }
      const imageURL = `${host}/${tokenPath}`;
      // console.log("imageURL:", imageURL);
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: imageURL,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const open = Boolean(anchorEl);

  const copyLink = () => {
    dispatch(success(t`Copy successful!`));
    setAnchorEl(null);
  };

  const handleDisconnect = () => {
    disconnect();
    setAnchorEl(null);
  };

  const goPancakeSwap = () => {
    // window.open(
    //   `https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
    // window.open(`https://quickswap.exchange/#/swap`, "_blank");
    // window.open(
    //   `https://pancakeswap.finance/swap?chain=${chainID === 56 ? "bsc" : "bscTestnet"}&inputCurrency=${
    //     addresses[chainID].USDT_ADDRESS
    //   }&outputCurrency=${addresses[chainID].OHM_ADDRESS}`,
    //   "_blank",
    // );
  };

  const handleOpenDexscreener = () => {
    window.open("", "_blank");
  };
  return (
    <Box>
      <RainbowConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openChainModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
          // Note: If your app doesn't use authentication, you
          // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== "loading";
          const connected =
            ready &&
            account &&
            chain &&
            (!authenticationStatus || authenticationStatus === "authenticated");

          return (
            <div
              {...(!ready && {
                "aria-hidden": true,
                style: {
                  opacity: 0,
                  pointerEvents: "none",
                  userSelect: "none",
                },
              })}
              // className={styles.connectButton}
            >
              {(() => {
                if (!connected) {
                  return (
                    <Box
                      component={"div"}
                      sx={{
                        width: "160px",
                        borderRadius: "6px",
                        height: "42px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: "transparent",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={openConnectModal}
                        variant="contained"
                        // onMouseOver={() => setIsHovering(true)}
                        // onMouseLeave={() => setIsHovering(false)}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                          }}
                        >
                          <Trans>Connect wallet</Trans>
                        </Typography>
                      </Button>
                    </Box>
                  );
                }

                if (chain.unsupported) {
                  if (!isWaringWrongNetwork) {
                    dispatch(
                      error(t`Wrong network, please switch to BSC mainnet 56`)
                    );
                    setIsWaringWrongNetwork(true);
                  }
                  return (
                    <Box
                      component={"div"}
                      sx={{
                        width: "160px",
                        borderRadius: "6px",
                        height: "42px",
                        border: "1px solid #000",
                        background: "#FCD000",
                        boxShadow: "-3px 3px 0px 0px #ECAA00",
                        "&:hover": {
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      <Button
                        sx={{
                          width: "100%",
                          height: "100%",
                          background: "transparent",
                          "&:hover": {
                            background: "transparent",
                          },
                        }}
                        onClick={openChainModal}
                        variant="contained"
                        onMouseOver={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                      >
                        <Typography
                          sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Wrong network</Trans>
                        </Typography>
                      </Button>
                    </Box>
                  );
                }

                return (
                  <Box
                    component={"div"}
                    sx={{
                      width: "160px",
                      borderRadius: "6px",
                      height: "42px",
                      border: "1px solid #000",
                      background: "#FCD000",
                      boxShadow: "-3px 3px 0px 0px #ECAA00",
                      "&:hover": {
                        transform: "scale(1.02)",
                      },
                    }}
                    // onMouseEnter={e => handleClick(e)}
                    // onMouseLeave={e => handleClick(e)}
                    onClick={(e) => handleClick(e)}
                  >
                    <Button
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        "&:hover": {
                          background: "transparent",
                        },
                      }}
                      // onClick={openAccountModal}
                      variant="contained"
                      // onMouseOver={() => setIsHovering(true)}
                      // onMouseLeave={() => setIsHovering(false)}
                    >
                      <Typography
                        sx={{
                          color: "#000",
                          fontSize: "14px",
                          fontWeight: "500",
                          textTransform: "uppercase",
                        }}
                      >
                        {account.displayName}
                      </Typography>
                    </Button>
                  </Box>
                );
              })()}
            </div>
          );
        }}
      </RainbowConnectButton.Custom>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom"
        transition
        sx={{ zIndex: "1112" }}
      >
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper
                elevation={1}
                sx={{
                  // display: showPopper ? "initial" : "none",
                  background: "none",
                  pt: "12px",
                  boxShadow: "none",
                }}
              >
                <Box
                  sx={{
                    width: "180px",
                    height: "38px",
                    // height: "142px",
                    background: "yellow",
                    borderRadius: "4px",
                    border: "1px solid #2D2D2D",
                    background: "#202020",
                    boxShadow:
                      "0px 49px 14px 0px rgba(0, 0, 0, 0.01), 0px 31px 13px 0px rgba(0, 0, 0, 0.09), 0px 18px 11px 0px rgba(0, 0, 0, 0.30), 0px 8px 8px 0px rgba(0, 0, 0, 0.51), 0px 2px 4px 0px rgba(0, 0, 0, 0.59)",
                  }}
                >
                  {/* <Box
                    sx={{ height: "54px", width: "100%", borderBottom: "1px solid #2d2d2d", px: "15px" }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box display="flex" alignItems={"center"}>
                      <OhmIcon />
                      <Typography
                        sx={{
                          ml: 1,
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>NVB</Trans>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => addTokenToWallet("OHM", OHM_ADDRESS)}
                    >
                      <Typography
                        sx={{
                          color: "#fcd000",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>Add</Trans>
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{ height: "54px", width: "100%", borderBottom: "1px solid #2d2d2d", px: "15px" }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Box display="flex" alignItems={"center"}>
                      <SohmIcon />
                      <Typography
                        sx={{
                          ml: 1,
                          color: "#fff",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>sNVB</Trans>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        cursor: "pointer",
                      }}
                      onClick={() => addTokenToWallet("sOHM", SOHM_ADDRESS)}
                    >
                      <Typography
                        sx={{
                          color: "#fcd000",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        <Trans>Add</Trans>
                      </Typography>
                    </Box>
                  </Box> */}
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={handleDisconnect}
                    sx={{
                      width: "100%",
                      height: "34px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#f15958",
                        fontSize: "12px",
                        fontWeight: "400",
                      }}
                    >
                      <Trans>Disconnect</Trans>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}

export default ConnectMenu;
