import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses, OHM_INITIAL_PRICE } from "../constants";
import CommunityAbiJson from "../abi/Community.json";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { bigNumber, setAll, getMarketPrice, fetchLotteryDataFromURL, toFullNumberString, toBN } from "../helpers";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import ierc20ABIJson from "../abi/IERC20.json";
import { error, info } from "./MessagesSlice";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";
import { getBalances, loadAccountDetails, fetchAccountSuccess } from "./AccountSlice";
import apollo from "../lib/apolloClient";
import sOHMv2Json from "../abi/sOhmv2.json";
import { INVITE_PER_PAGE } from "src/constants";
import { fetchQueryStakingStatus } from "../helpers";
import { t } from "@lingui/macro";
import dayjs from "dayjs";
const sOHMv2 = sOHMv2Json.abi;
const CommunityAbi = CommunityAbiJson.abi;


const ierc20ABI = ierc20ABIJson.abi;

export const communityJoin = createAsyncThunk(
  "community/communityJoin",
  async ({ provider, networkID, referer }: any, { dispatch }: any) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }
    // const signer = provider.getSigner();
    const signer = provider;
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      signer,
    );
    let tx;
    try {
      const estimateGas = await communityContract.estimateGas.join(referer);
      tx = await communityContract.join(referer, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const text = "Join";
      const pendingTxnType = "community_join";
      if (tx) {
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text, type: pendingTxnType }));
        await tx.wait();
        return;
      }
    } catch (e: unknown) {
      // dispatch(error((e as any).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else if ((e as any).reason.indexOf("Referrer is not member")) {
        console.log("reason", (e as any).reason);
        console.log("message", (e as any).message);
        console.log("data", (e as any).data);
        // dispatch(error((e as any).message));
        dispatch(error(t`Your recommender has not joined the community yet！`));
      } else {
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (tx) {
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  },
);

export const getCommunityData = createAsyncThunk(
  "community/getCommunityData",
  async ({ provider, networkID, address }: any, { dispatch }: any) => {
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      provider,
    );
    console.log("getCommunityData 1", address);
    try {
      const membersData = await communityContract.members(address);
      let referrals = await communityContract.referrals(address, 0, 0);

      return {
        isInvited: membersData.referrer !== "0x0000000000000000000000000000000000000000",
        referrer: membersData.referrer,
        directReferralQuantity: Number(referrals._length),
      };
    } catch (error) {
      console.log("getCommunityData error", error);
      return {
        isInvited: false,
        referrer: "",
      };
    }
  },
);

export const getInviteDataTemp = createAsyncThunk(
  "community/getInviteDataTemp",
  async ({ provider, networkID, address, page }: any, { dispatch }: any) => {
    const communityContract = new ethers.Contract(
      addresses[networkID].COMMUNITY_ADDRESS as string,
      CommunityAbi,
      provider,
    );
    console.log("getCommunityData 1", address, page);
    try {
      let referrals = await communityContract.referrals(address, 0, 0);
      console.log("getCommunityData 3", referrals._length, INVITE_PER_PAGE * page);
      if (referrals._length >= INVITE_PER_PAGE * page) {
        referrals = await communityContract.referrals(address, INVITE_PER_PAGE * (page - 1), INVITE_PER_PAGE);
      } else {
        referrals = await communityContract.referrals(
          address,
          INVITE_PER_PAGE * (page - 1),
          referrals._length - INVITE_PER_PAGE * (page - 1),
        );
      }
      console.log("getCommunityData 4", referrals);

      return {
        referralsList: referrals.referrals_,
      };
    } catch (error) {
      console.log("getCommunityData error", error);
      return {
        referralsList: [],
      };
    }
  },
);

interface ICommunitySlice {
  referralsList?: any[];
  isInvited?: boolean;
  referrer?: string;
  directReferralQuantity?: number;
  teamNumber?: string;
  stakeMinGons?: string;
  stakeMinAmount?: string;
}

const initialState: ICommunitySlice = {
};

const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    fetchCommunitySliceSuccess(state: any, action: { payload: any }) {
      setAll(state, action.payload);
    },
  },
  extraReducers: (builder: any) => {
    builder
      .addCase(getCommunityData.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getCommunityData.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getCommunityData.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getInviteDataTemp.pending, (state: { loading: boolean }) => {
        state.loading = true;
      })
      .addCase(getInviteDataTemp.fulfilled, (state: { loading: boolean }, action: { payload: any }) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getInviteDataTemp.rejected, (state: { loading: boolean }, { error }: any) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default communitySlice.reducer;

export const { fetchCommunitySliceSuccess } = communitySlice.actions;

const baseInfo = (state: any) => state.communitySlice;

export const getCommunitySliceState = createSelector(baseInfo, (community: any) => community);
