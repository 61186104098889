import { ethers, BigNumber, BigNumberish } from "ethers";
import { contractForRedeemHelper, getTokenDecimals, setAll } from "../helpers";
import { getBalances, calculateUserBondDetails, calculateUserDirectBondDetails } from "./AccountSlice";
import { findOrLoadMarketPrice, loadAppDetails } from "./AppSlice";
import { error, info } from "./MessagesSlice";
import { clearPendingTxn, fetchPendingTxns } from "./PendingTxnsSlice";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { getBondCalculator } from "src/helpers/BondCalculator";
import ierc20ABIJson from "../abi/IERC20.json";
import { RootState } from "src/store";
import { t } from "@lingui/macro";
import {
  IApproveBondAsyncThunk,
  IBondAssetAsyncThunk,
  ICalcBondDetailsAsyncThunk,
  IJsonRPCError,
  IRedeemAllBondsAsyncThunk,
  IRedeemBondAsyncThunk,
} from "./interfaces";
// import { segmentUA } from "../helpers/userAnalyticHelpers";
import { addresses } from "../constants";

const ierc20ABI = ierc20ABIJson.abi;

export const changeApproval = createAsyncThunk(
  "bonding/changeApproval",
  async ({ address, bond, provider, networkID, selVal }: IApproveBondAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const reserveContract = bond.getContractForReserve(networkID, signer);
    const daiContract = new ethers.Contract(addresses[networkID].DAI_ADDRESS as string, ierc20ABI, signer);
    // const bondAddr = bond.getAddressForBondHelper(networkID);
    let approveTx: any;

    try {
      if (selVal == "0" && bond.name === "ohm_dai_lp") {
        const estimateGas = await daiContract.estimateGas.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", "ether").toString(),
        );

        approveTx = await daiContract.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", "ether").toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (selVal == "1") {
        // 1 usdt 2 usdc
        const usdtContract = new ethers.Contract(addresses[networkID].USDT_REAL_ADDRESS as string, ierc20ABI, signer);
        const decimals = await getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS, networkID);

        const estimateGas = await usdtContract.estimateGas.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
        );
        approveTx = await usdtContract.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else if (selVal == "2") {
        // 1 usdt 2 usdc
        const usdcContract = new ethers.Contract(addresses[networkID].USDC_ADDRESS as string, ierc20ABI, signer);
        const decimals = await getTokenDecimals(addresses[networkID].USDC_ADDRESS, networkID);

        const estimateGas = await usdcContract.estimateGas.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
        );
        approveTx = await usdcContract.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      } else {
        const decimals = await getTokenDecimals(reserveContract.address, networkID);

        console.log("decimals", reserveContract.address, reserveContract);
        const estimateGas = await reserveContract.estimateGas.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
        );
        approveTx = await reserveContract.approve(
          bond.getAddressForBondHelper(networkID) as string,
          ethers.utils.parseUnits("1000000000", decimals).toString(),
          {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          },
        );
      }
      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          text: "Approving " + bond.displayName,
          type: "approve_" + bond.name,
        }),
      );
      await approveTx?.wait();
    } catch (e: unknown) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
        dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      }
    }
  },
);

export interface IBondDetails {
  bond: string;
  bondDiscount: number;
  debtRatio: number;
  bondQuote: number;
  purchased: number;
  vestingTerm: number;
  maxBondPrice: number;
  bondPrice: number;
  marketPrice: number;
  tokenPrice?: number;
}
export const calcBondDetails = createAsyncThunk(
  "bonding/calcBondDetails",
  async (
    { bond, value, provider, networkID, selVal }: ICalcBondDetailsAsyncThunk,
    { dispatch },
  ): Promise<IBondDetails> => {
    if (!value || value === "") {
      value = "0";
    }
    try {
      // const decimals = await getTokenDecimals(bond.getAddressForReserve(networkID), networkID);

      // const amountInWei = ethers.utils.parseUnits(value, decimals);
      const amountInWei = ethers.utils.parseEther(value);
      let bondPrice = 0,
        bondDiscount = 0,
        valuation = 0,
        bondQuote = 0;

      const bondContract = bond.getContractForBond(networkID, provider);
      const bondCalcContract = getBondCalculator(networkID, provider);
      console.log("calcBondDetails bondCalcContract", bondCalcContract);
      // bond.displayName === "wETH" && console.log("bondasd 1", bondContract);
      const terms = await bondContract.terms();
      // bond.displayName === "wETH" && console.log("bondasd 2", terms);
      const maxBondPrice = await bondContract.maxPayout();

      // let debtRatio: BigNumberish;
      const debtRatio = (await bondContract.standardizedDebtRatio()) / Math.pow(10, 9);

      let marketPrice: number = 0;

      // TODO (appleseed): improve this logic
      // if (bond.name === "cvx") {
      //   debtRatio = await bondContract.debtRatio();
      // } else {
      //   debtRatio = await bondContract.standardizedDebtRatio();
      // }
      // debtRatio = Number(debtRatio.toString()) / Math.pow(10, 9);

      // let marketPrice: number = 0;
      try {
        const originalPromiseResult = await dispatch(
          findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
      }
      console.log("calcBondDetails marketp", marketPrice);
      try {
        // TODO (appleseed): improve this logic
        // if (bond.name === "cvx") {
        //   let bondPriceRaw = await bondContract.bondPrice();
        //   let assetPriceUSD = await bond.getBondReservePrice(networkID, provider);
        //   let assetPriceBN = ethers.utils.parseUnits(assetPriceUSD.toString(), 14);
        //   // bondPriceRaw has 4 extra decimals, so add 14 to assetPrice, for 18 total
        //   bondPrice = bondPriceRaw.mul(assetPriceBN);
        // } else {
        //   bondPrice = await bondContract.bondPriceInUSD();
        // }
        bondPrice = await bondContract.bondPriceInUSD();
        console.log("bondPrice", bondPrice, bond.displayName);
        if (bond.name === "usdt") {
          bondPrice = bondPrice / Math.pow(10, 6);
        } else {
          bondPrice = bondPrice / Math.pow(10, 18);
        }
        // const bondPrice1 = await bondContract.bondPrice();

        bondDiscount = (marketPrice - bondPrice) / bondPrice; // 1 - bondPrice / (bondPrice * Math.pow(10, 9));
        console.log("bondPrice1", bondDiscount, marketPrice, bondPrice, bond.displayName);
      } catch (e) {
        console.log("error getting bondPriceInUSD", bond.name, e);
      }
      console.log("bondPrice", bondPrice, bond.displayName);
      if (Number(value) === 0) {
        // if inputValue is 0 avoid the bondQuote calls
        bondQuote = 0;
      } else if (bond.isLP) {
        try {
          // if (selVal == "1") {
          //   valuation = await bondCalcContract.valuation(bond.getAddressForReserve(networkID), amountInWei);
          //   bondQuote = await bondContract.payoutFor(valuation);
          // } else {
          const bondHelperContract = bond.getContractForBondHelper(networkID, provider);
          bondQuote = await bondHelperContract.depositValue(amountInWei);
          // }
          // console.log("bondQuote", bondQuote, bondHelperContract.address);
          if (!amountInWei.isZero() && bondQuote < 100000) {
            bondQuote = 0;
            const errorString = "Amount is too small!";
            dispatch(error(errorString));
          } else {
            bondQuote = bondQuote / Math.pow(10, 9);
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        // RFV = DAI
        bondQuote = await bondContract.payoutFor(amountInWei);
        console.log("decimals amount", amountInWei, bondQuote);

        if (!amountInWei.isZero() && bondQuote < 100000000000000) {
          bondQuote = 0;
          const errorString = "Amount is too small!";
          dispatch(error(errorString));
        } else {
          // const decimals = await getTokenDecimals(bond.getAddressForReserve(networkID), networkID);
          bondQuote = bondQuote / Math.pow(10, 18);
        }
      }

      // Display error if user tries to exceed maximum.
      if (!!value && parseFloat(bondQuote.toString()) > maxBondPrice / Math.pow(10, 9)) {
        const errorString =
          t`You're trying to bond more than the maximum payout available! The maximum bond payout is ` +
          (maxBondPrice / Math.pow(10, 9)).toFixed(4).toString() +
          " OHM.";
        dispatch(error(errorString));
      }

      // Calculate bonds purchased
      let purchased = await bond.getTreasuryBalance(networkID, provider);
      console.log("purchased", bond.name, purchased);
      let tokenPrice = BigNumber.from(0);
      if (bondContract.assetPrice) {
        tokenPrice = await bondContract.assetPrice();
      }
      // console.log("decimals tokenPrice: " + tokenPrice);
      return {
        bond: bond.name,
        bondDiscount,
        debtRatio,
        bondQuote,
        purchased,
        vestingTerm: Number(terms.vestingTerm.toString()),
        maxBondPrice: maxBondPrice / Math.pow(10, 9),
        bondPrice: bondPrice,
        marketPrice: marketPrice,
        tokenPrice: Number(tokenPrice.toString()) / Math.pow(10, 8),
      };
    } catch (error) {
      console.log("calcBondDetails error", error);
      return {
        bond: bond.name,
        bondDiscount: 0,
        debtRatio: 0,
        bondQuote: 0,
        purchased: 0,
        vestingTerm: 0,
        maxBondPrice: 0,
        bondPrice: 0,
        marketPrice: 0,
        tokenPrice: 0,
      };
    }
  },
);

export const bondAsset = createAsyncThunk(
  "bonding/bondAsset",
  async ({ value, address, bond, networkID, provider, slippage, selVal }: IBondAssetAsyncThunk, { dispatch }: any) => {
    try {
      const depositorAddress = address;
      const acceptedSlippage = slippage / 100 || 0.005; // 0.5% as default
      // parseUnits takes String => BigNumber
      let valueInWei = ethers.utils.parseUnits(value.toString(), "ether");
      let balance;
      // Calculate maxPremium based on premium and slippage.
      // const calculatePremium = await bonding.calculatePremium();
      const signer = provider;
      // const signer = provider.getSigner();
      const bondContract = bond.getContractForBond(networkID, signer);

      let calculatePremium, maxPremium;
      // const calculatePremium = await bondContract.bondPrice();
      calculatePremium = await bondContract.bondPrice();
      maxPremium = Math.round(Number(calculatePremium.toString()) * (1 + acceptedSlippage));
      // const maxPremium = Math.round(Number(calculatePremium.toString()) * (1 + acceptedSlippage));
      // console.log("valueInWei", bond.name, valueInWei, maxPremium);
      // Deposit the bond
      let bondTx;
      console.log("bondAsset", bond.name);
      try {
        if (bond.name !== "ohm_dai_lp") {
          const reserve = await bond.getContractForReserve(networkID, signer);
          console.log("bondAsset reserve", reserve.address, bond.name);
          // const decimals = await bondHelperContract.decimals();
          // console.log("bondHelperContract decimals", bondHelperContract.address);
          // if (bond.name === "btc") {
          //   const decimals = await getTokenDecimals(addresses[networkID].BTC_ADDRESS, networkID);
          //   valueInWei = ethers.utils.parseUnits(value.toString(), decimals);
          // } else if (bond.name === "eth") {
          //   const decimals = await getTokenDecimals(addresses[networkID].ETH_ADDRESS, networkID);
          //   valueInWei = ethers.utils.parseUnits(value.toString(), decimals);
          // } else if (bond.name === "usdt") {
          //   const decimals = await getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS, networkID);
          // }
          const decimals = await getTokenDecimals(reserve.address, networkID);
          valueInWei = ethers.utils.parseUnits(value.toString(), decimals);
          console.log("decimals", valueInWei, decimals);
          const estimateGas = await bondContract.estimateGas.deposit(valueInWei, maxPremium, depositorAddress);
          // console.log("bond assets", bondContract);
          bondTx = await bondContract.deposit(valueInWei, maxPremium, depositorAddress, {
            gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
          });
          // bondTx = await bondContract.deposit(valueInWei, maxPremium, depositorAddress, { gasLimit: 1000000 });
          // bondTx = await bondContract.deposit(
          //   valueInWei,
          //   maxPremium,
          //   // "0x0000000000000000000000000000000000000000",
          //   addresses[networkID].DAI_ADDRESS,
          //   // { value: valueInWei },
          // );
        } else {
          const bondHelperContract = bond.getContractForBondHelper(networkID, signer);
          console.log("bondAsset bondHelperContract", selVal, bondHelperContract.address);
          if (selVal == "0") {
            console.log(
              "bondAsset",
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              bondHelperContract.address,
            );
            const estimateGas = await bondHelperContract.estimateGas.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              "0x0000000000000000000000000000000000000000",
            );

            bondTx = await bondHelperContract.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              "0x0000000000000000000000000000000000000000",
              {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
              },
            );
          } else if (selVal == "1") {
            console.log(
              "asbcc",
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              addresses[networkID].USDT_REAL_ADDRESS,
            );
            const tokenDecimals = await getTokenDecimals(addresses[networkID].USDT_REAL_ADDRESS, networkID);
            valueInWei = ethers.utils.parseUnits(value.toString(), tokenDecimals);
            const estimateGas = await bondHelperContract.estimateGas.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              addresses[networkID].USDT_REAL_ADDRESS,
            );
            bondTx = await bondHelperContract.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              addresses[networkID].USDT_REAL_ADDRESS,
              {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
              },
            );
          } else if (selVal == "2") {
            const tokenDecimals = await getTokenDecimals(addresses[networkID].USDC_ADDRESS, networkID);
            valueInWei = ethers.utils.parseUnits(value.toString(), tokenDecimals);
            const estimateGas = await bondHelperContract.estimateGas.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              addresses[networkID].USDC_ADDRESS,
            );
            bondTx = await bondHelperContract.depositHelper(
              valueInWei,
              maxPremium,
              addresses[networkID].USDT_ADDRESS,
              addresses[networkID].USDC_ADDRESS,
              {
                gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
              },
            );
          }
        }
        // else if (selVal == "2") {
        //   bondTx = await bondHelperContract.depositHelper(valueInWei, maxPremium, bond.getAddressForReserve(networkID));
        // }
        dispatch(
          fetchPendingTxns({ txnHash: bondTx.hash, text: "Bonding " + bond.displayName, type: "bond_" + bond.name }),
        );
        await bondTx.wait();
        // TODO: it may make more sense to only have it in the finally.
        // UX preference (show pending after txn complete or after balance updated)

        dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
        dispatch(calcBondDetails({ bond, value, provider, networkID, selVal }));
        dispatch(loadAppDetails({ provider, networkID }));
      } catch (e: unknown) {
        console.log("e", (e as any).reason, (e as any).message, (e as any).data, { ...(e as any) });
        const rpcError = e as IJsonRPCError;
        if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
          dispatch(
            error(
              "You may be trying to bond more than your balance! Error code: 32603. Message: ds-math-sub-underflow",
            ),
          );
        } else if ((e as any).code == "ACTION_REJECTED") {
          dispatch(error(t`User denied transaction signature.`));
          // dispatch(error((e as any).message));
        } else if (e == "cancel") {
          dispatch(error(t`User denied transaction signature.`));
        } else {
          dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
        }
      } finally {
        if (bondTx) {
          // segmentUA(uaData);
          dispatch(clearPendingTxn(bondTx.hash));
        }
      }
    } catch (error) {
      console.log("bondAsset error", error);
    }
  },
);

export const redeemBond = createAsyncThunk(
  "bonding/redeemBond",
  async (
    { address, bond, networkID, provider, autostake, id, isInvite, bondName }: IRedeemBondAsyncThunk,
    { dispatch }: any,
  ) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const bondContract = bond.getContractForBond(networkID, signer);

    let redeemTx;
    // let uaData = {
    //   address: address,
    //   type: "Redeem",
    //   bondName: bond.displayName,
    //   autoStake: autostake,
    //   approved: true,
    //   txHash: "",
    // };
    try {
      if (isInvite) {
        const estimateGas = await bondContract.estimateGas.redeemForInviteBond(address);
        redeemTx = await bondContract.redeemForInviteBond(address, {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
      } else {
        const estimateGas = await bondContract.estimateGas.redeem(address, id, autostake === true);

        redeemTx = await bondContract.redeem(address, id, autostake === true, {
          gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
        });
      }
      const pendingTxnType = "redeem_bond";
      // const pendingTxnType = "redeem_bond_" + bondName + (autostake === true ? "_autostake" : "");
      // console.log("address, (bond as any).id, autostake === true", pendingTxnType, type, type == pendingTxnType);
      // uaData.txHash = redeemTx.hash;
      dispatch(
        fetchPendingTxns({ txnHash: redeemTx.hash, text: "Redeeming " + bond.displayName, type: pendingTxnType }),
      );

      await redeemTx.wait();
      await dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      await dispatch(calculateUserDirectBondDetails({ address, bond, networkID, provider }));

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      // uaData.approved = false;
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (redeemTx) {
        // segmentUA(uaData);
        dispatch(clearPendingTxn(redeemTx.hash));
      }
    }
  },
);

export const redeemAllBonds = createAsyncThunk(
  "bonding/redeemAllBonds",
  async ({ bonds, address, networkID, provider, autostake }: IRedeemAllBondsAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error(t`Please connect your wallet!`));
      return;
    }

    // const signer = provider.getSigner();
    const signer = provider;
    const redeemHelperContract = contractForRedeemHelper({ networkID, provider: signer });

    let redeemAllTx;
    let idsArray: any[] = [];

    if (bonds) {
      bonds.forEach(_bond => {
        // console.log("_bond", (_bond as any)[0]);
        for (const tempBond in _bond as any) {
          if ((_bond as any)[tempBond] && (_bond as any)[tempBond]["id"]) {
            idsArray.push((_bond as any)[tempBond]["id"]);
          }
        }
      });
    }
    try {
      const estimateGas = await redeemHelperContract.estimateGas.redeemAll(address, idsArray, autostake, false);

      redeemAllTx = await redeemHelperContract.redeemAll(address, idsArray, autostake, false, {
        gasLimit: estimateGas.add(ethers.utils.parseUnits("100000", "wei")),
      });
      const pendingTxnType = "redeem_all_bonds" + (autostake === true ? "_autostake" : "");

      await dispatch(
        fetchPendingTxns({ txnHash: redeemAllTx.hash, text: "Redeeming All Bonds", type: pendingTxnType }),
      );

      await redeemAllTx.wait();

      bonds &&
        bonds.forEach(async bond => {
          dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
        });

      dispatch(getBalances({ address, networkID, provider }));
    } catch (e: unknown) {
      // dispatch(error((e as IJsonRPCError).message));
      if ((e as any).code == "ACTION_REJECTED") {
        dispatch(error(t`User denied transaction signature.`));
        // dispatch(error((e as any).message));
      } else if (e == "cancel") {
        dispatch(error(t`User denied transaction signature.`));
      } else {
        // dispatch(error((e as any).message));
        dispatch(error((e as any).reason || (e as any).message || (e as any).data || (e as any)));
      }
      return;
    } finally {
      if (redeemAllTx) {
        dispatch(clearPendingTxn(redeemAllTx.hash));
      }
    }
  },
);

// Note(zx): this is a barebones interface for the state. Update to be more accurate
interface IBondSlice {
  status: string;
  [key: string]: any;
}

export const setBondState = (state: IBondSlice, payload: any) => {
  const bond = payload.bond;
  const newState = { ...state[bond], ...payload };
  state[bond] = newState;
  state.loading = false;
};

const initialState: IBondSlice = {
  status: "idle",
};

const bondingSlice = createSlice({
  name: "bonding",
  initialState,
  reducers: {
    fetchBondSuccess(state, action) {
      state[action.payload.bond] = action.payload;
    },
    setBondStateReducer(state, action) {
      setAll(state, action.payload);
    },
  },

  extraReducers: builder => {
    builder
      .addCase(calcBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calcBondDetails.fulfilled, (state, action) => {
        setBondState(state, action.payload);
        state.loading = false;
      })
      .addCase(calcBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.message);
      });
  },
});

export default bondingSlice.reducer;

export const { fetchBondSuccess } = bondingSlice.actions;

const baseInfo = (state: RootState) => state.bonding;

export const getBondingState = createSelector(baseInfo, bonding => bonding);
