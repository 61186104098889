import { createTheme } from "@mui/material";
import fonts from "./fonts";

const breakpointValues = {
  xs: 0,
  sm: 792,
  md: 1080,
  lg: 1440,
  xl: 1980,
};

const theme = createTheme({
  breakpoints: { values: breakpointValues },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@global": {
          "@font-face": fonts,
          body: {
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
            backgroundColor: "#000",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily:
            "HarmonyOS Sans,PingFang SC,OSPunctuation,Microsoft Yahei,Heiti SC,WenQuanYi Micro Hei,Helvetica Neue,Helvetica,Arial,sans-serif!important",
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily:
            "HarmonyOS Sans,PingFang SC,OSPunctuation,Microsoft Yahei,Heiti SC,WenQuanYi Micro Hei,Helvetica Neue,Helvetica,Arial,sans-serif !important",
          textTransform: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed !important",
            color: "#fff !important",
            "&>.MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          },
          // "&.main-btn": {
          //   "&:hover": {
          //     background: "linear-gradient(180deg, #FF6760 0%, #B50C05 100%)",
          //     boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
          //   },
          // },
          // "&.second-btn": {
          //   "&:hover": {
          //     background: "linear-gradient(180deg, #B50C05 0%, #FF6760 100%)",
          //     boxShadow: "5px 5px 9px 0px #070707, -6px -2px 16px 0px #455059",
          //   },
          // },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&.Mui-disabled": {
            opacity: 0.5,
            cursor: "not-allowed",
            "&>.MuiLoadingButton-loadingIndicator": {
              color: "white",
            },
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          zIndex: 20000,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.linear-card": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          "@media (max-width: 680px)": {
            "&.linear-card": {
              padding: "1px",
              borderRadius: "14px",
            },
          },
        },
      },
    },
  },
});

export default theme;
