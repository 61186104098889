import { useEffect, useState, useCallback, useRef, useMemo } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import muiTheme from "./themes/muiGlobal";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds, { IAllBondData } from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import useSegmentAnalytics from "./hooks/useSegmentAnalytics";
import { segmentUA } from "./helpers/userAnalyticHelpers";
import { t, Trans } from "@lingui/macro";
import { shouldTriggerSafetyCheck } from "./helpers";
import { ethers } from "ethers";
import {
  darkTheme as rainbowDarkTheme,
  RainbowKitProvider,
  Theme,
} from "@rainbow-me/rainbowkit";
import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails } from "./slices/AppSlice";
import {
  loadAccountDetails,
  calculateUserBondDetails,
  calculateUserDirectBondDetails,
} from "./slices/AccountSlice";
import { info, error } from "./slices/MessagesSlice";
import { Stake, ChooseBond, Bond, TreasuryDashboard, Invite } from "./views";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import Messages from "./components/Messages/Messages";
import Footer from "./components/Footer/Footer";
import NotFound from "./views/404/NotFound";
import { chains } from "src/hooks/wagmi";
import merge from "lodash.merge";
import { useBlockNumber } from "wagmi";
import { dark as darkTheme } from "./themes/dark.js";
import { light as lightTheme } from "./themes/light.js";
import { girth as gTheme } from "./themes/girth.js";
import "./style.scss";
import Home from "./views/Home/Home";
import { DEFAULT_INVITE_ADDRESS } from "./constants";
import { communityJoin, getCommunityData } from "src/slices/CommunitySlice";
import InviteModal from "./components/InviteModal";
import { useAppSelector } from "src/hooks";
import SmoothScroll from "smooth-scroll";

// 😬 Sorry for all the console logging
const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet BSCMainnet");

const drawerWidth = 280;
const transitionDuration = 969;

function App() {
  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInviteAddr, setModalInviteAddr] = useState("");
  const [theme, toggleTheme, mounted] = useTheme();
  const currentPath = location.pathname + location.search + location.hash;

  const scrollTarget = useRef(null);
  const scroll = useMemo(() => new SmoothScroll(), []);

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  // const classes = useStyles();
  const { provider, chainID, connected, address } = useWeb3Context();
  const isInvited = useAppSelector((state) => state.community.isInvited);
  const referrer = useAppSelector((state) => state.community.referrer);

  const [walletChecked, setWalletChecked] = useState(false);

  // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
  const { bonds } = useBonds(chainID);
  // console.log("bonds", bonds);

  async function loadDetails(whichDetails) {
    // NOTE (unbanksy): If you encounter the following error:
    // Unhandled Rejection (Error): call revert exception (method="balanceOf(address)", errorArgs=null, errorName=null, errorSignature=null, reason=null, code=CALL_EXCEPTION, version=abi/5.4.0)
    // it's because the initial provider loaded always starts with chainID=1. This causes
    // address lookup on the wrong chain which then throws the error. To properly resolve this,
    // we shouldn't be initializing to chainID=1 in web3Context without first listening for the
    // network. To actually test rinkeby, change setChainID equal to 4 before testing.

    if (whichDetails === "app") {
      loadApp(
        new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL)
      );
    }
    // don't run unless provider is a Wallet...
    if (whichDetails === "account" && address && connected && isLoad) {
      loadAccount(provider);
      // loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    (loadProvider) => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));

      bonds.map((bond) => {
        console.log("bond app", chainID);
        dispatch(
          calcBondDetails({
            bond,
            value: "",
            provider: loadProvider,
            networkID: chainID,
          })
        );
      });
      setIsLoad(true);
    },
    [connected, address, currentPath, chainID]
  );

  const loadAccount = useCallback(
    (loadProvider) => {
      dispatch(
        loadAccountDetails({ networkID: chainID, address, provider: provider })
      );
      dispatch(getCommunityData({ provider, networkID: chainID, address }));
      bonds.map((bond) => {
        // console.log("bond", bond);
        dispatch(
          calculateUserBondDetails({
            address,
            bond,
            provider,
            networkID: chainID,
          })
        );
        dispatch(
          calculateUserDirectBondDetails({
            address,
            bond,
            provider,
            networkID: chainID,
          })
        );
        // chainID == 56 && dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      });
      // expiredBonds.map(bond => {
      //   dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      // });
    },
    [connected, address]
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    // if (mobileOpen) {

    // setIsSidebarExpanded(false);
    // }
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  let themeMode =
    theme === "light" ? lightTheme : theme === "dark" ? darkTheme : gTheme;

  useEffect(() => {
    themeMode = theme === "light" ? lightTheme : darkTheme;
  }, [theme]);

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
    setMobileOpen(false);
    // console.log("location.pathname", isSidebarExpanded);
  }, [location.pathname]);

  const customTheme = merge(
    rainbowDarkTheme({
      accentColor: "#676B74",
      borderRadius: "medium",
    }),
    {
      colors: {
        modalBackground: "#151515",
        modalBorder: "#323232",
      },
      shadows: {
        dialog: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
      },
    }
  );

  useEffect(() => {
    if (connected && referrer !== "0x0000000000000000000000000000000000000000")
      return;
    if (location.search.indexOf("code") > -1) {
      let obj = {};
      let params = location.search.substring(1).split("&");

      params.map((_) => {
        obj[_.split("=")[0]] = _.split("=")[1];
      });

      setModalInviteAddr(obj.code);
      setModalOpen(true);
    }
  }, [address, connected, location.search, referrer]);

  const handleJoin = async () => {
    if (modalInviteAddr?.toLowerCase() === address?.toLowerCase()) {
      return dispatch(error(t`You can't invite yourself!`));
    }
    await dispatch(
      communityJoin({
        provider,
        referer: modalInviteAddr,
        networkID: chainID,
      })
    );
    setModalOpen(false);
    dispatch(getCommunityData({ provider, networkID: chainID, address }));
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleScrollTop = () => {
    scroll.animateScroll(scrollTarget.current, undefined, {
      easing: "easeInOutCubic",
    });
  };
  return (
    <RainbowKitProvider chains={chains} theme={customTheme}>
      <ThemeProvider theme={themeMode}>
        <MuiThemeProvider theme={muiTheme}>
          <CssBaseline />
          {/* {isAppLoading && <LoadingSplash />} */}
          <div
            ref={scrollTarget}
            className={`app ${isSmallerScreen && "tablet"} ${
              isSmallScreen && "mobile"
            } ${theme} `}
          >
            <Messages />
            <TopBar
              theme={theme}
              toggleTheme={toggleTheme}
              handleDrawerToggle={handleDrawerToggle}
            />
            <NavDrawer
              mobileOpen={mobileOpen}
              handleDrawerToggle={handleDrawerToggle}
            />

            <Box
              sx={{
                pt: isSmallScreen ? "76px" : 0,
              }}
            >
              {/* <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}> */}
              <Switch>
                <Route exact path="/home">
                  <Home handleScrollTop={handleScrollTop} />
                </Route>

                <Route path="/invite">
                  <Invite handleScrollTop={handleScrollTop} />
                </Route>

                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
                <Route component={NotFound} />
              </Switch>
              {/* <div className={`${location.pathname.replace("/", "")}Bg  rightBg`}></div> */}
            </Box>
            <InviteModal
              open={modalOpen}
              // open
              handleClose={handleClose}
              inviteAddr={modalInviteAddr}
              isInvited={isInvited}
              handleJoin={handleJoin}
            />
          </div>
        </MuiThemeProvider>
      </ThemeProvider>
    </RainbowKitProvider>
  );
}

export default App;
