import { memo } from "react";
import "./treasury-dashboard.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Skeleton } from "@mui/material";
import {
  Paper,
  Grid,
  Box,
  Zoom,
  Container,
  useMediaQuery,
  Typography,
} from "@mui/material";
import {
  MarketCap,
  OHMPrice,
  TotalMinting,
  SOHMPrice,
  CircSupply,
  BackingPerOHM,
  TotalSupply,
  CurrentIndex,
} from "./components/Metric/Metric";
import { useAppSelector } from "src/hooks";
import { formatCurrency, toolNumber, trim } from "../../helpers";
import { allBondsMap } from "src/helpers/AllBonds";
import Chart from "src/components/Chart/Chart.jsx";

import {
  TotalValueDepositedGraph,
  MarketValueGraph,
  RiskFreeValueGraph,
  ProtocolOwnedLiquidityGraph,
  OHMStakedGraph,
  APYOverTimeGraph,
  RunwayAvailableGraph,
} from "./components/Graph/Graph";
import styled from "@emotion/styled";
import { Trans } from "@lingui/macro";

// const Paper = styled((props: any) => <Paper {...props} />)(({ theme }) => ({
//   borderRadius: theme.breakpoints.sm ? "12px" : "24px",
//   border: theme.breakpoints.sm ? "1px solid #434952" : "2px solid #434952",
//   background: "linear-gradient(180deg, #34393f 0%, #18191d 100%, #181a1d 100%)",
//   boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.4)",
//   padding: "20px",
//   minHeight: "370px",
// }));

const TreasuryDashboard = memo(() => {
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 480px)");
  const isAppLoading: boolean = useAppSelector((state) => state.app.loading);

  const marketPrice = useAppSelector((state) => {
    return state.app.marketPrice;
  });

  const stakingTVL = marketPrice as number;

  const stakingAPY = useAppSelector((state) => {
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });

  const StakeRate: number | undefined = useAppSelector((state) => {
    return state.app.StakeRate;
  });
  const trimmedStakingAPY = trim(stakingAPY * 100, 2);
  const currentIndex = useAppSelector((state) => {
    return state.app.currentIndex;
  });
  const totalReserves = useAppSelector((state) => {
    return state.app.totalReserves;
  });

  return (
    <div
      id="treasury-dashboard-view"
      className={`${isSmallScreen && "smaller"} ${
        isVerySmallScreen && "very-small"
      }`}
    >
      <Container
        style={{
          paddingLeft: isVerySmallScreen
            ? "15px"
            : isSmallScreen
            ? "15px"
            : "8rem",
          paddingRight: isVerySmallScreen
            ? "15px"
            : isSmallScreen
            ? "15px"
            : "8rem",
          marginBottom: "40px",
        }}
      >
        <Box className="hero-metrics">
          {!isSmallScreen ? (
            <Paper className="ohm-card linear-card" sx={{}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  borderRadius: isSmallScreen ? "12px" : "24px",
                  //  border: "2px solid #434952",
                  background:
                    "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                  // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  py: 6,
                }}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <MarketCap />
                <TotalMinting />
                {/* <SOHMPrice /> */}
                <OHMPrice />
                {/* <TotalSupply />
              <BackingPerOHM />
              <CurrentIndex /> */}
              </Box>
            </Paper>
          ) : (
            <Box>
              <Box
                sx={{
                  py: 2.5,
                }}
              >
                <Typography
                  sx={{
                    color: "#C1C2C6",
                    textShadow: "0px 1px 0px #000",
                    fontSize: "30px",
                    fontStyle: "normal",
                    fontWeight: "500",
                  }}
                  align="left"
                >
                  <Trans>Dashboard</Trans>
                </Typography>
              </Box>
              <Grid container>
                <Grid item xs={12}>
                  <Paper
                    className="linear-card"
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        py: 3.125,
                        borderRadius: "12px",
                        background:
                          "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <MarketCap />
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    className="linear-card"
                    sx={{
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        py: 3.125,
                        borderRadius: "12px",
                        background:
                          "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <TotalMinting />
                    </Box>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    mb: 1,
                  }}
                >
                  <Paper className="linear-card">
                    <Box
                      sx={{
                        width: "100%",
                        height: "100%",
                        py: 3.125,
                        borderRadius: "12px",
                        background:
                          "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                        // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                      }}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <OHMPrice />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <Zoom in={true}>
          <Grid container spacing={2} className="data-grid">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <TotalValueDepositedGraph />
                </Box>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <RiskFreeValueGraph />
                </Box>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <ProtocolOwnedLiquidityGraph />
                </Box>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <OHMStakedGraph />
                </Box>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <RunwayAvailableGraph />
                </Box>
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card linear-card">
                <Box
                  sx={{
                    p: isSmallScreen ? 1.875 : 2.5,
                    width: "100%",
                    height: "100%",
                    borderRadius: isSmallScreen ? "12px" : "24px",
                    background:
                      "linear-gradient(180deg, #34393F 0%, #18191D 100%, #181A1D 100%)",
                    // boxShadow: "14px 14px 100px 0px rgba(0, 0, 0, 0.40)",
                  }}
                >
                  <MarketValueGraph />
                </Box>
              </Paper>
            </Grid>

            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <MarketValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <RiskFreeValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <ProtocolOwnedLiquidityGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <OHMStakedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <RunwayAvailableGraph />
              </Paper>
            </Grid> */}
          </Grid>
        </Zoom>
      </Container>
    </div>
  );
});

const queryClient = new QueryClient();

// Normally this would be done
// much higher up in our App.
export default () => (
  <QueryClientProvider client={queryClient}>
    <TreasuryDashboard />
  </QueryClientProvider>
);
