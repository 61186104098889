import { BigNumber } from "ethers";
import DaiBondContractJson from "src/abi/bonds/DaiContract.json";

export const THE_GRAPH_URL = "";


export const EPOCH_INTERVAL = 14400;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 2;

export const OHM_INITIAL_PRICE = BigNumber.from("1000000000000000000");

export const TOKEN_DECIMALS = 9;

export const INVITE_PER_PAGE = 20;

interface IPoolGraphURLS {
  [index: string]: string;
}

export const DEFAULT_INVITE_ADDRESS = "";

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  1: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  97: {
    COMMUNITY_ADDRESS: "0x19A4399f68675cF3258896Ec91367146eDfa3AC2",
  },

  56: {
    COMMUNITY_ADDRESS: "0xFf26f3D2324Dc1C8c5813feC07c1b3630f5da329",
    commRoot: "0x3E4901a01c1C9625e6927872c3AD54eB0d58B6eA",
  },
};
