import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "src/lib/Bond";
import { addresses } from "src/constants";
import BondOhmLusdHelperContractJson from "src/abi/BondHelper.json";

import { ReactComponent as BusdImg } from "src/assets/tokens/BUSD.svg";
import { ReactComponent as USDTImg } from "src/assets/tokens/USDT.svg";
import { ReactComponent as DaiImg } from "src/assets/tokens/bond_dai.svg";
import { ReactComponent as TokenDAIImg } from "src/assets/tokens/token_dai.svg";
// import { ReactComponent as TokenUSDTImg } from "src/assets/tokens/token-usdt.svg";
import { ReactComponent as FraxImg } from "src/assets/tokens/FRAX.svg";
import { ReactComponent as OhmFraxImg } from "src/assets/tokens/OHM-FRAX.svg";
import { ReactComponent as OhmLusdImg } from "src/assets/tokens/OHM-LUSD.svg";
import { ReactComponent as OhmEthImg } from "src/assets/tokens/OHM-WETH.svg";
import { ReactComponent as wETHImg } from "src/assets/tokens/wETH.svg";
import { ReactComponent as BTCBImg } from "src/assets/tokens/btcb.svg";
import { ReactComponent as LusdImg } from "src/assets/tokens/LUSD.svg";
import { ReactComponent as CvxImg } from "src/assets/tokens/CVX.svg";
import FraxOhmBondContractJson from "src/abi/bonds/OhmFraxContract.json";
import BondOhmLusdContractJson from "src/abi/bonds/OhmLusdContract.json";
import BondOhmEthContractJson from "src/abi/bonds/OhmEthContract.json";

import DaiBondContractJson from "src/abi/bonds/DaiContract.json";
import ReserveOhmLusdContractJson from "src/abi/reserves/OhmLusd.json";
import ReserveOhmDaiContractJson from "src/abi/reserves/OhmDai.json";
import ReserveOhmFraxContractJson from "src/abi/reserves/OhmFrax.json";
import ReserveOhmEthContractJson from "src/abi/reserves/OhmEth.json";

import FraxBondContractJson from "src/abi/bonds/FraxContract.json";
import LusdBondContractJson from "src/abi/bonds/LusdContract.json";
import CvxBondContractJson from "src/abi/bonds/CvxContract.json";
import BondOhmDaiContractJson from "src/abi/bonds/OhmDaiContract.json";
import ierc20AbiJson from "src/abi/IERC20.json";
import { getBondCalculator } from "src/helpers/BondCalculator";
import { BigNumberish } from "ethers";
import { getTokenPrice } from "src/helpers";

const BondOhmLusdContract = BondOhmLusdContractJson.abi;
const BondOhmEthContract = BondOhmEthContractJson.abi;
const FraxBondContract = FraxBondContractJson.abi;
const LusdBondContract = LusdBondContractJson.abi;
const CvxBondContract = CvxBondContractJson.abi;
const FraxOhmBondContract = FraxOhmBondContractJson.abi;
const DaiBondContract = DaiBondContractJson.abi;
const BondOhmLusdHelperContract = BondOhmLusdHelperContractJson.abi;
const ReserveOhmDaiContract = ReserveOhmDaiContractJson.abi;
const ReserveOhmLusdContract = ReserveOhmLusdContractJson.abi;
const ReserveOhmFraxContract = ReserveOhmFraxContractJson.abi;
const ReserveOhmEthContract = ReserveOhmEthContractJson.abi;
const BondOhmDaiContract = BondOhmDaiContractJson.abi;
const ierc20Abi = ierc20AbiJson.abi;










export const ohm_dai = new LPBond({
  name: "ohm_dai_lp",
  displayName: "OHM-DAI LP",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: TokenDAIImg,
  bondContractABI: BondOhmDaiContract,
  reserveContract: ReserveOhmDaiContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "",
      reserveAddress: "",
      bondHelperAddress: "",
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
      bondHelperAddress: "",
    },
  },
  lpUrl: "",
});



// HOW TO ADD A NEW BOND:
// Is it a stableCoin bond? use `new StableBond`
// Is it an LP Bond? use `new LPBond`
// Add new bonds to this array!!

export const allBonds = [ohm_dai];
// export const allBonds = [ohm_dai, usdt, eth, btc, dai];
// export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, usdt, eth, btc];
// export const allBonds = [ohm_dai, usdt, eth, btc, link, dai, usdc];
// export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, busd];
// export const allBonds = [ohm_dai, busd];
// TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
export const allExpiredBonds = [];
// export const allExpiredBonds = [cvx_expired];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
export default allBonds;
